import axios from "axios";
export class LunaJoyFoundationService {
  static FOUNDATION_URL = process.env.REACT_APP_FOUNDATION_LINK;
  static FOUNDATION_API_KEY = process.env.REACT_APP_FOUNDATION_API_KEY;
  
  static getClinicDetails = async () => {
    try {
      const response = await axios.get(
        `${LunaJoyFoundationService.FOUNDATION_URL}/api/carePartners`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": LunaJoyFoundationService.FOUNDATION_API_KEY || "",
          },
        }
      );
      
      if (response.status === 200) {
        const resData = response.data;
        return resData;
      } else {
        throw {
          message: "Could not fetch events.",
          status: 500,
        };
      }
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };
  
  static getClinicByCode = async (code) => {
    try {
      const response = await axios.get(
        `${LunaJoyFoundationService.FOUNDATION_URL}/api/carePartners/code/${code}`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": LunaJoyFoundationService.FOUNDATION_API_KEY || "",
          },
        }
      );

      if (response.status === 200) {
        const resData = response.data;
        return resData;
      } else {
        throw {
          message: "Could not fetch events.",
          status: 500,
        };
      }
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };

  static findOrCreateContactWithEmail = async (formData) => {
    try {
      const response = await axios.post(
        `${LunaJoyFoundationService.FOUNDATION_URL}/api/ghl/find-or-create-by-field`,
        {
          by: "email",
          email: formData?.email,
          from: formData?.from,
          tags: formData?.tags,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": LunaJoyFoundationService.FOUNDATION_API_KEY || "",
          },
        }
      );

      if (response.status === 200) {
        const resData = response.data;
        return resData;
      } else {
        throw {
          message: "Could not fetch events.",
          status: 500,
        };
      }
    } catch (error) {
      console.error("Error:", error);
      return null;
    }
  };

  static updateGHLContact = async (formData) => {
    try {
      const response = await axios.patch(
        `${LunaJoyFoundationService.FOUNDATION_URL}/api/ghl/updateContact`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": LunaJoyFoundationService.FOUNDATION_API_KEY || "",
          },
        }
      );

      if (response.status === 201) {
        const resData = response.data;
        return resData;
      } else {
        throw {
          message: "Could not fetch events.",
          status: 500,
        };
      }
    } catch (error) {
      console.error("Error:", error);
      return null;
    }
  }
}
